.site-bottom{
    background: #14191e;
}
/*Newsletter Section*/
.newsletter-area{
    .subscription-form{
        @include mx-mobile-xs{
            margin-top: 10px;
        }
        @include mx-mobile{
            margin-top: 25px;
        }
    }
}
.newsletter-details{
    h3{
    color: #ffffff;
    font-size: 22px;
    margin: 0;
    text-transform: capitalize;
    font-family: 'Prata', serif;
    font-weight: 400;
    line-height: 30px;
    @include desktops{
        font-size: 30px;
    }
    }
    h6{
        font-size: 14px;
        color: $text-color;
        font-weight: 300;
        margin: 10px 0 0;
        text-transform: inherit;
        line-height: 24px;
        @include desktops{
            font-size: 13px;
            margin: 8px 0 0;
        }
    }
}



/* Newsletter */ 
.subscription-form{
    display: flex;
    position: relative;
    label{
        color: $text-color;
    }
    .form-control{
        height: 55px;
        width: 100%;
        padding: 0 160px 0 20px;
        background: #1e252e;
        font-size: 13px;
        color: #fff;;
        font-weight: 300;
        font-style: italic;
        display: block;
        height: 55px;
        width: 100%;
        float: left;
        margin: 0;
        border: 0;
        border-radius: 3px;
        &:focus{
            outline: none;
            border: none;
            box-shadow: none;
        }
    }
    .theme-btn{
        padding: 0 40px;
        height: 55px;
        line-height: 60px;
        margin-top: 15px;
        @include mobile{
            margin-top: 0px;
            position: absolute;
            right: 0;
            top: 0;
        }
        font-size: 12px;
        &:hover{
            background: #333 !important;
            color: #fff!important;
        }
    }
}

/* Footer Inner */ 
.footer-inner{
    padding-bottom: 7px;
    padding-top: 50px;
    border-top: 1px solid #30363c;
    border-bottom: 1px solid #30363c;
    color: $text-color;
    .single-footer{
        // padding-bottom: 25px;
    }
    @include desktops{
        padding-bottom: 35px;
        
    }
}
.footer-title{
    font-size: 18px;
    color: #ffffff;
    text-transform: capitalize;
    font-weight: 400;
    font-family: 'Prata', serif;
    margin: 0;
    margin-bottom: 25px;
}
.contact-block{
    p{
        margin-top: 10px;
        margin-bottom: 0;
        line-height: 25px;
    }
}
.single-footer{
    .social-links{
        margin-top: 17px;
        @include desktops{
            margin-top: 35px;
        }
    }
}
.footer-list{
    li{
        line-height: 32px;
    }
    a{
        font-size: 14px;
        color: $text-color;
        font-weight: 300;
        text-transform: capitalize;
        &:hover{
            color: $primary-color;
        }
    }
}
.instagram-photos{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    .single-image{
        
        padding-right:15px;
        padding-bottom: 15px; 
        width: 50%;
        @include mobile{
            width: 16.333%;
        }
        @include tablet{
            width: 26.333%;
        }
        @include desktops{
            width: 33.3333%;
        }
    }
}
.footer-features{
    padding-top: 30px;
    @include desktops{
        padding: 40px 0;
    }
}
.copyright-area{
    padding: 40px 0 40px;
    border-top: 1px solid #30363c;
    text-align: center;
    color: $text-color;
    @include tablet{
        text-align: left;
    }
    p{
        a{
            color: #fff;
            &:hover{
                color: $primary-color;
            }
        }
        @include tablet{
            margin-bottom: 0;
        }
    }
}

.payment-cards{
    .single-card{
        margin-left: 10px;
        @include mx-tablet{
            margin-left: 3px;
        }
        @include mx-mobile-xs{
            margin-bottom: 10px;
        }
        &:first-child{
            margin-left: 0;
        }
    }
}



.contact-widget{
    @include flex;
    .widget-text{
        font-size: 18px;
        color: #ffffff;
        font-weight: 500;
        margin: 0 0 0 12px;
        line-height: 48px;
        
    }
}


/* Mini Features*/
.mini-feature{
    @include flex;
    color: $text-color;
    .feature-icon{
        width: 55px;
        text-align: center;
        i{
            font-size: 21px;
            color: inherit;
            line-height: 52px;
            width: 55px;
            height: 55px;
            border: 2px solid $text-color;
            border-radius: 100%;
        }
    }
    .feature-text{
        margin-left: 15px;
        @include desktops{
            margin-left: 10px;
        }
        h3{
            color: inherit;
            font-weight: 400;
            margin: 0;
            text-transform: capitalize;
            font-family: 'Prata', serif;
            font-size: 15px;
            @include large-desktops{
                font-size: 16px;
            }
        }
        p{
            font-size: 14px;
            color: inherit;
            margin: 6px 0 0;
            font-weight: 300;
        }
    }
}
/* footer */
#back-top:hover{
	background:#b3936d;
}
#back-top {
	position: fixed;
	bottom: 210px;
	right: 9%;
	width:40px;
	height:40px;
	line-height:40px;
	text-align: center;
	cursor: pointer;
	color: #fff;
	font-weight: 700;
	text-transform: uppercase;
	background: #14191e;
	z-index: 999;
	-webkit-transition: ease-in-out .3s all;
    -moz-transition: ease-in-out .3s all;
    transition: ease-in-out .3s all;
}
@include large-desktops{
	#back-top{
		bottom: 60px;
		right: 15px;		
	}
}
#back-top span{
	margin-right: 15px;
}
#back-top i{
	font-size: 12px;
}


#scrollUp {
    width: 40px;
    height: 40px;
    background:#b3936d;
    color: #ffffff;
    right: 20px;
    bottom: 60px;
    line-height: 40px;
    text-align: center;
    overflow: hidden;
}
