/* #####++++ Belly - Why Choose Us ++++####*/ 
.why-choose-accordion{
    .card{
        border-bottom: 1px solid #ececec!important;
        .card-header{
            padding: 0;
            border-radius: 3px 3px 0 0;
            border-bottom: 0;
            background: $primary-color-2;
            button{
                width: 100%;
                text-align: left;
                color: #fff;
                font-size: 16px;                
                text-transform: capitalize;
                font-family: 'Prata', serif;
                background: $primary-color-3;
                transition: .4s background;
                padding: 20px 10px 16px;
                @include tablet{
                    padding: 20px 20px 16px;
                }
                &.collapsed{
                    background: #fff;
                    color: #494949;
                    &:after{
                        background: #e7e7e7;
                        color: #fff;
                    }
                }
                &:hover{
                    text-decoration: none;
                }
                &:after{
                    position: absolute;
                    content:"\f280";
                    font-family: "Ionicons";
                    right: 0;
                    display: block;
                    font-family: "Ionicons";
                    line-height: 22px;
                    font-size: 24px;
                    height: 20px;
                    width: 20px;
                    background: #fff;
                    color: #5fbd74;
                    border-radius: 100%;
                    text-align: center;
                    position: absolute;
                    right: 15px;
                    top: 18px;
                }
            }
        }
    }
}

