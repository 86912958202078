.promo-details-mini{
    text-align: center;
    @include flex-column(center,center);
    height: 100%;
    position: relative;
    .promo-content{
        padding: 30px 15px;
    }
    &.bg-pattern-1{
        background-image:url(../image/mini-item-images/promo-mini-bg-1.jpg); 
        @extend .bg-image;
    }
    &.bg-pattern-2{
        background-image:url(../image/mini-item-images/promo-mini-bg-2.jpg); 
        @extend .bg-image;
    }
    &.bg-pattern-3{
        background-image:url(../image/mini-item-images/home-2__promo-mini-bg-1.jpg); 
        @extend .bg-image;
    }
    &:before{
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        pointer-events: none;
        -webkit-transition: ease .3s all;
        -moz-transition: ease .3s all;
        transition: ease .3s all;
    }
    &:hover{
        &::before{
            box-shadow: 0 0 0 15px rgba(0,0,0,0.2) inset;
            
        }
    }
    .promo-icon{
        @include desktops{
            width: 40px;
            margin: 0 auto;
        }
    }
    .promo-description{
        padding: 0 30px;
        @include desktops{
            padding: 0 20px;
        }
        @include mobile-xs{
            padding: 0 5px;
        }
        h2{
            font-size: 24px;
            color: #fff;
            text-transform: capitalize;
            font-weight: 400;
            margin: 23px 0 0;
            font-family: 'Prata', serif;
            @include mobile-xs{
                margin: 20px 0 0;
                line-height: 38px;
            }
            @include desktops{
                line-height: 35px;
            }
        }
        p{
            font-size: 14px;
            color: $text-color;
            margin: 20px 0 0;
            line-height: 22px;
            font-weight: 300;
        }
        .promo-btn{
            margin-top: 50px;
            a{
                height: 55px;
                line-height: 53px;
                font-size: 13px;
                padding: 0 30px;
                font-weight: 500;
                text-transform: uppercase;
            }
            @include desktops{
                margin-top: 35px;
            }
        }
    }
    .promo-image{
        width: 100%;
        img{
            width: 100%;
        }
    }
}