/* Belly Banners*/ 
.banner-content-wrapper{
    height: 500px;
    @include tablet{
        height: 740px;
    }
    @include desktops{
        height: 100vh;
    }
    .banner-content{
        display: flex;
        height: 100%;
        align-items: center;
        width: 100%;
        @include desktops{
            padding-top: 95px;
        }
        .title--sm{
            text-transform: capitalize;
            color: inherit;
            font-weight: 400;
            margin: 0;
            line-height: 1;
            font-family: 'Prata', serif;
            font-size: 22px;
           @include desktops{
               font-size: 30px;
           }
        }
        .title--lg{
            margin: 20px 0 0;
            color: inherit;
            text-transform: capitalize;
            font-weight: 400;
            font-family: 'Prata', serif;
            @include tablet{
                font-size: 50px;
            }
            @include desktops{
                font-size: 55px;
            }
            @include large-desktops{
                font-size: 65px;
            }
            @include mx-mobile-xs{
                margin: 18px 0 0;
                font-size: 32px;
                line-height: 42px;            
            }
            @include mx-mobile{
                margin: 18px 0 0;
                font-size: 35px;
                line-height: 45px;
            }
        }
        .title--xl{
            margin: 20px 0 0;
            color: inherit;
            text-transform: capitalize;
            font-weight: 400;
            font-family: 'Prata', serif;
            @include desktops{
                font-size: 90px;
            }
        }
        .title--md{
            margin: 20px 0 0;
            color: inherit;
            text-transform: capitalize;
            font-weight: 400;
            font-family: 'Prata', serif;
            font-size: 58px;
        }
        p{
            color: inherit;
            font-size: 14px;
            font-weight: 300;
            line-height: 22px;
            font-family: 'Rubik', sans-serif;
            margin-top: 15px;
            
            @include desktops{
                margin: 30px 0 0;
            }
        }
        .banner_btn{
            margin-top: 25px;
            @include tablet{
                margin-top: 50px;
            }
                
                a{
                    height: 45px;
                    line-height: 45px;
                    padding: 0 25px;
                    @include desktops{
                        height: 60px;
                        line-height: 60px;
                        padding: 0 40px;
                    }
                }
        }
        &.single-slide{
            height: 500px;
            @include mx-mobile-xs{
                padding-top: 75px;
            }
            @include mx-mobile{
                padding-top:80px; 

            }
            @include tablet{ 
                height: 740px;
            }
            @include desktops{
                height: 100vh;
            }
            &:focus{
                outline: none;
            }
        }
    }
    &.banner-2{
        height: 100%;
        .banner-content{
            padding: 90px 0;
            @include desktops{
                padding: 230px 0;
            }
        }
        .title--sm{
            font-size: 13px;
            color: inherit;
            text-transform: uppercase;
            font-weight: 500;
            margin: 0;
            letter-spacing: 5px;
            font-family: 'Rubik', sans-serif;
        }
        .title--lg{
            font-size: 36px;
            color: inherit;
            text-transform: capitalize;
            font-family: 'Prata', serif;
            font-weight: 400;
            margin: 15px 0 0;
            padding-bottom: 35px;
            @include mx-mobile-xs{
                font-size: 30px;
            }
        }
        p{
            font-size: 14px;
            color: inherit;
            font-weight: 300;
            line-height: 25px;
            margin: 35px 0 0;
        }
        .banner_btn{
            margin-top: 60px;
            
        }
    }
}


/* Hero Slick Slider */
/*Banner Slider*/
.hero-slider-wrapper{
    position: relative;
    .slick-arrow{
        width: 60px;
        height: 60px;
        line-height: 60px;
        background: rgba(0,0,0,0.1);
        color: #fff;
        margin: -30px 0 0;
        display: block;
        position: absolute;
        cursor: pointer;
        z-index: 9;
        text-align: center;
        top: 50%;
        margin-left: 50px;
        border-radius: 100% !important;
        opacity: 0;
        transition: .4s;
        &.slick-next{
            margin-left: 0px;
            margin-right: 50px;
            right: 0;
        }
        &:hover{
            color: #b3936d;
            background: #fff;
        }
    }
    &:hover{
        .slick-arrow{
            opacity: 1;
        }
        
    }
}  


/* Single Block Review */
.review-head {
    margin-bottom: 10px;
    h3 {
        font-size: 1.8rem;
        margin-bottom: 5px;
        span {
            font-size: 1.2rem;
        }
    }
    p {
        margin-bottom: 15px;
    } 
}
.single-review{
    margin-bottom:15px; 
    .single-review-head{
        span.name {
            font-size: 17px;
            font-weight: 500;
            margin-right: 7px;
        }
    
        .rating-widget {
            padding: 0 5px 10px 0;
        }
        .single-rating {
            font-size: 14px!important;
        }
    }
}

.review-form{
    .review-form-head{
        h4{
            font-size: 1.8rem;
            font-weight: 500;
        }
    }
    
}
.form-box-1{
    .form-control{
        border: 2px solid #565f69;
        background: transparent;
        font-size: 14px;
        color: #fff;
        &:focus{
            outline:none;
            box-shadow: none;
            border-color: #b2b2b2;
        }
    }
    input{
        border-radius: 3px;
        height: 45px;
    }
    label{
        color: $text-color;
    }
    .form-btn{
        .theme-btn-otlined,.theme-btn,button,a{
            height: 50px;
            padding: 0 40px;
        }
    }
}

.home-4--banner{
    .banner-content{
       @include desktops{
        padding: 140px 0!important;
       }
    }
}