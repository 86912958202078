
/* Theme Light Version*/ 
@import "scss-helpers";
body.belly-light-version{
    $primary-color : $primary-color;
    $body-color:#fff;
    $text-color:#151515;
    // $primary-color:#fff;
    $black-color:#14191e;
    $text-color-2:#6f6565;
    $border-color: #e8e7e7;

    .site-wrapper{
        background: $body-color;
        color: $text-color;
    }
    
    .initial-static{
        background: $black-color;
    }
    .belly-bredcrumb-section .breadcrumb a,.shop-toolbar a.sortting-btn,.shop-toolbar .sorting-selection #input-sort, .shop-toolbar .sorting-selection #input-limit ,.pagination-widget,.sidebar-title,.belly-product .product__caption,.belly-product .rating-text,.form-box-1 label,.rating-block label:before,.belly-mini-blog .blog-content .blog-title,.single-blog-item .content .blog-title,.calculate-shipping h4,.discount-coupon h4,.checkout-cart-total p,.compare-table .table tbody tr td.pro-remove button,.cart-summary .cart-summary-wrap h4,.login-form .check-box input[type="checkbox"] + label::after,.ct_title,.address_wrapper .address .icon i{
        color: $text-color;
    }
    .theme-btn-outlined--type-2,.widget--list.list-light,.belly-product.product-view--list .product-info-para,.belly-product--details .product-description--list,.belly-product--details .product-buttons--group .single-btn-block input,.theme-btn-outlined,.belly-review-tab .tab-content .tab-pane,.belly-review-tab .nav-link,.belly-product--details .product-description--breif,.product-list-group .product-name,.belly-mini-blog .blog-content p,.sidebar-post .single-post,.sidebar-post.tweet-posts p,.single-blog-item .content .desc p,.single-blog-item .content .blog-footer .tags a, .single-blog-item .content .blog-footer .share a,.comment .content,.blog-navigation a,.cart-table .table tbody tr td,.calculate-shipping form .nice-select .current,.cart-summary .cart-summary-button button,.checkout-form,.checkout-form input,.checkout-form .nice-select,.checkout-form .nice-select .current,.checkout-form .check-box input[type="checkbox"] + label,.checkout-form .check-box input[type="checkbox"] + label::after,.single-method input[type="radio"] + label,.single-method input[type="checkbox"] + label,.single-method p,.checkout-cart-total ul li span,.myaccount-content,.login-form,.compare-table .table tbody tr td.first-column,.compare-table .table tbody tr td.product-image-title .category,.compare-table .table tbody tr td.product-image-title .title,.compare-table .table tbody tr td.pro-desc p,.compare-table .table tbody tr td.pro-price,.compare-table .table tbody tr td,.compare-table,.count-input-block input,.count-input-block input:focus,.cart-summary .cart-summary-wrap p,.cart-summary .cart-summary-wrap h2,.myaccount-tab-menu a,.login-form .check-box input[type="checkbox"] + label,.ct_address p,.address_wrapper .address .contact-info-text p{
        color: $text-color-2;
    }
    
    // Product add
    .belly-product--details .product-buttons--group .single-btn-block input,.count-input-block input,.checkout-form input,.checkout-form input.form-control:focus,.count-input-block input:focus,.checkout-form .nice-select,.checkout-payment-method,.checkout-cart-total,.myaccount-content,.login-form,.cart-summary .cart-summary-wrap{
        background: #ffff;
        border: 2px solid $text-color;
    }
    
    .theme-btn:hover,.single-method input[type="radio"] + label::after,.single-method input[type="checkbox"] + label::after{
        background: $text-color;
        color: $body-color;
    }
    
    .cart-summary .cart-summary-button button{
        border-color: $primary-color;
    }
    .myaccount-tab-menu{
        border-color: $text-color;
    }
    .myaccount-tab-menu{
        background-color: $white-color;
    }
    .theme-btn-outlined:hover,.cart-summary .cart-summary-button button,.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active{
        background: $primary-color;
        color: #fff;
    }
    input{
        @include placeholder{
            color: $text-color-2!important;
        }
    }
    
    .compare-table .table tbody tr td.first-column,.compare-table .table tbody tr td,.compare-table .table tbody tr td,.compare-table .table tbody tr td:last-child,.compare-table .table tbody tr td,table,.compare-table .table tbody tr:first-child,.pagination-widget,.shop-toolbar,.cart-table .table tbody tr td,.cart-summary .cart-summary-wrap h2{
        border-color:$border-color!important;
    }
    
}


// 
