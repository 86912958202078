/*Single Service*/

.belly-service{
    text-align: center;
    .service-heading{
        color: #171e26;
        @include title(18px!important,400,$font-prata);
        padding-bottom: 15px;
        position: relative;
        &:before{
            content: '';
            width: 30px;
            height: 2px;
            background: #e1e1e1;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
            display: inline-block;
        }
        a{
            color: inherit;

        }
        &:hover{
            color: $primary-color;
        }
    }
    .service-details{
        margin-top: 15px;
        p{
            margin-top: 15px;
            margin-bottom: 0;
        }
    }
}

