$primary-color: #B3936D;
$primary-color-2:#e02c2c;
$primary-color-3:#5fbd74;
$white-color:#ffff;
$white:$white-color;


$btn-color-2: #565f69;
$text-color:#d4d4d4;
$dots-color:#353b43;
$btn-color-2--border: #828b96;
$color-bunker: #14191E;
$black: #222;
$color-bunker-2: #171e26;
// $primary-color: #B3936D;
// $body-color:#555555;
// $balck-color-2:#555555;
// $text-color:#d4d4d4;
// $white-color:#fff;
// $border-color: #565f69;


$text-color:#d4d4d4;
$border-color: #565f69;
// $primary-color-gradient: linear-gradient( -90deg, rgb(189,247,103) 0%, rgb(146,217,78) 43%, rgb(102,186,52) 100%);


/*Font Families*/
$font-rubik:'Rubik', sans-serif;
$font-prata:'Prata', serif;
$font-shadow-cursive:'Shadows Into Light', cursive;
$font-arvo:'Arvo', serif;