/* Product Modal */ 
.modal.modal-quick-view{
    display: block;
    opacity: 0;
    visibility: hidden;
    z-index: -9999;
    .modal-dialog{

        max-width: 100%;
        margin: 20% auto;
        @include tablet{
            max-width: 700px;
            overflow-x: scroll;
        }
        @include desktops{
            max-width: 900px;
            overflow: unset;
            margin: 10% auto;
           
        }
        @include large-desktops{
            max-width: 1100px;
            overflow: unset;
        }

    }
    .modal-content{
        background: #171e26;
        opacity: 1;
    }
    .cross-btn{
        button.close {
            position: absolute;
            right: 10px;
            background: #ffff;
            z-index: 666666;
            opacity: 1;
            padding: 5px 10px;
            font-weight: 33;
            font-size: 31px;
            margin-top: 10px;
            }
    }
    &.show{
        opacity: 1;
        visibility: visible;
        z-index: 9999;
    }
    .belly-product--details {
    .product__image{
        width: 300px;
        height: 100%;
        margin: 0 auto;
        @include mobile{
            width: 400px;
        }
        @include tablet{
            width: 300px;
        }
        @include large-desktops{
            width: 400px;
        }
        
    }
    .product-image-main{
        position: relative !important;

    }
    .product-view-slider {
        @include mx-mobile-xs{
            margin-top: -100px;
        }
    }
}
    .belly-product--details .product-image-main .zoomWrapper{
            // height: 100%!important;
            margin: 0 auto;
        img{
            @include mx-desktops{
                // width: 350px;;
            }
            @include mx-mobile{
                // position: static!important;
            }
            @include desktops{
                // position: absolute;
            }
        }
    }
}
.elevet-enable{
    .zoomContainer {
        display: block!important;
    }
}
.zoomContainer {
    display: none!important;
}