/* Classes Used for colors */ 
.text-primary{
    color: $primary-color!important;
}
.text-white{
    color: #fff;
}

.text-black{
    color: $color-bunker-2!important;
}