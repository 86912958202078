@mixin title-sep{
    position: relative;
    &:before{
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 100%;
        height: 6px; 
        background-repeat: no-repeat;
        @content;
    }
}
@mixin flex($justify:flex-start,$align:center){
    display: flex;
    justify-content: $justify;
    align-items: $align;
}
@mixin inline-flex($justify:flex-start,$align:center){
    display: inline-flex;
    justify-content: $justify;
    align-items: $align;
}
@mixin flex-column($justify:center,$align:flex-start){
    display: flex;
    flex-direction: column;
    justify-content: $justify;
    align-items: $align;
}

@mixin title($size,$weight:400,$family:$font-rubik) {
    font-size:$size;
    font-weight:$weight!important;
    font-family:$family!important;
}

@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
      @content;
    }
  }
  
  @mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
      @content;
    }
  
    @include optional-at-root(':-moz-placeholder') {
      @content;
    }
  
    @include optional-at-root('::-moz-placeholder') {
      @content;
    }
  
    @include optional-at-root(':-ms-input-placeholder') {
      @content;
    }
  }