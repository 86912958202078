/*Backgrounds Images*/
.bg-image{
    background-size:cover;
    background-repeat: no-repeat;
    position: relative; 
    z-index: 2;
    background-position: center;
} 

.testimonial-bg{
    background-image:url(../image/banner-images/bg-testimonial.jpg); 
}
.testimonial-bg-2{
    background-image:url(../image/banner-images/home-2__bg-testimonial.jpg); 
}
.testimonial-bg-3{
    background-image:url(../image/banner-images/testimonial-bg-3.png)
}
.testimonial-bg-4{
    background-image:url(../image/banner-images/testimonial-bg-4.png); 
}
.banner4-bg-img-1{
    background-image:url(../image/banner-images/banner4-bg-img-2.png)
}
// .footer-bg{
//     background-image:url(../image/banner-images/bg-footer.jpg); 
// }


/* Badge Images */ 
.badge{
    &--sell{
        &-green{
            background-image: url(../image/icons/badge-sell-3.png);
        }
        &-red{
            background-image: url(../image/icons/badge-sell-2.png);
        }
        &-chocolate{
            background-image: url(../image/icons/badge-sell.png);
        }
    }
    &--new{
        background-image: url(../image/icons/badge-new.png);
    }
}


.service-bg-image{
    position: relative;
    @include desktops{
        padding-bottom: 215px!important;
    }
    &:before{
        content: '';
        background: url(../image/bg-images/home-3/service-bg.png) no-repeat 0 0;
        // background-attachment: fixed;
        background-size: 100%;
        width: 100%;
        height: 209px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-position: bottom;
    } 
}
