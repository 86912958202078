/* newsletter popup */
.b-close{
	display: block;
}
.b-close:before {
	font-family: "Ionicons";
	content: "\f2c0";
	display:inline-block;
	color:#434343;
	font-size:16px;
}
.b-close span { display:none; }
.newletter-popup{
	background: #fff;
    top: 50% !important;
    margin-top: -179px;
    position: fixed !important;
    padding: 50px;
    text-align: center;
    display: none;
}
#boxes .newletter-title h2{
	font-size: 30px;
	text-transform: uppercase;
	color: #343434;
	margin: 0 0 30px;
}
#boxes .box-content label{
	font-weight: 400;
	font-size:13px;
}
#boxes .box-content .newletter-label {
	width:70%;
}
#boxes  #frm_subscribe .required{
	color: #f00;
	padding-right: 5px;
}
#boxes  #frm_subscribe a{
    cursor: pointer;
    height: 45px;
    &:hover{
        color: #ffff;
    }
}
#boxes  #frm_subscribe #subscribe_pemail{
	background: #EBEBEB none repeat scroll 0% 0%;
	border: medium none;
	height: 40px;
	width: 50%;
	margin: 20px 0 30px;
	padding: 0 15px;
}
#boxes .box-content .subscribe-bottom{
	margin-top: 20px;
}
#boxes .box-content .subscribe-bottom  label{
	margin: 0;
	font-size:13px;
}
#boxes .box-content .subscribe-bottom #newsletter_popup_dont_show_again{
	display: inline-block;
	margin: 0;
}
#boxes  #frm_subscribe #notification{
	color: #f00;
}
#boxes  #frm_subscribe #notification .success{
	color: #67D7BB;
}
#popup2{
	position: absolute;
	right: -12px;
	top: -12px;
	width: 25px;
	height: 25px;
	line-height: 25px;
	text-align: center;
	background: #fff;
	border-radius: 100%;
	cursor: pointer;
}