
/* Products*/
.belly-product{
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
    padding: 5px 15px 25px;
    transition: .4s;
    .product__image{
        position: relative;
        img{
            width: 100%;
        }
    }
    &:hover{
        box-shadow:0px 0px 10px 0px rgba(0, 0, 0, 0.2)
    }
    .product-contents{
        text-align: center;
    }
    .hover-content {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .4s;
        
    }
    .hover-btns {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0,-50%);
        display: flex;
        justify-content: center;
        width: 100%;
        z-index: 7;
        @include mx-mobile{
            top: 58%;
        }
        .single-btn{
            line-height: 42px;
            height: 45px;
            width: 45px;
            border-radius: 3px;
            padding: 0;
            position: relative;
            text-align: center;
            font-weight: 400;
            color: #b3936d;
            font-size: 20px;
            cursor: pointer;
            border: 2px solid #b3936d;
            background: none;
            -webkit-transition: ease .5s all;
            -moz-transition: ease .5s all;
            transition: ease .5s all;
            @include mx-mobile{
                line-height: 36px;
                height: 38px;
                width: 37px;
            }
            +.single-btn{
                margin-left: 8px;
            }
            &:hover{
                background: $primary-color;
                color: #fff;
            }
        }
        &.btn-type-2{
            .single-btn{
                    color: $primary-color;
                    background: #fff;
                    -webkit-transition: ease .5s all;
                    -moz-transition: ease .5s all;
                    transition: ease .5s all;
                    border-color: #fff;
                    &:hover{
                        background: $primary-color;
                        color: #fff;
                        border-color: $primary-color;
                    } 
            }
        }
    }
    .hover-image{
        position: relative;
        display: block;
        z-index: 6;
        &:before{
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background: #171e26;
            opacity: 0.7;
        }
    }
    &:hover{
        .hover-content{
            opacity: 1;
        }
    }
    .product-description,.product-contents{
        text-align: center;
        padding-top:20px;
     }
    
    .product-info-para{
        display: none; 
        
    }
    .product__caption{
        font-size: 14px;
        text-transform: capitalize;
        margin: 0 0 15px;
        line-height: 18px;
        font-weight: 400;
        color: #fff;
        a{
            color: inherit;
            &:hover{
                color: $primary-color;
            }
        }
    }
    .product__btn{
        margin: 15px 0 0;
        a{
            padding: 0 35px;
            height: 45px;
            line-height: 16px;
            font-size: 12px;
            @include inline-flex;
        }
    }
    .product__price{
        color: #b3936d;
        font-size: 16px;
        margin: 0;
        font-weight: 500;
        .price{
            &--old{
                color: #4d473f;
                font-size: 14px;
                text-decoration: line-through;
                margin-right: 8px;
                font-weight: 400;
            }
        }
    }
    .product-badge{
        position: absolute;
        top: -5px;
        right: 0;
        width: 31px;
        height: 61px;
        font-size: 0;
        display: inline-block;
        z-index: 9;
    }
     &.product-type-1{
         background: #f5f5f7;
     }


    /* Product White BG*/  
    &.product-style-2{
        background: #f5f5f7;
        .product__caption{
            color: #171e26;
            
        }
        .hover-image{
            &:before{
                background: #f5f5f7
            }
        }
        .hover-content .hover-btns .single-btn{
            &:hover{
                color: $white-color;
            }
        }
       
    }

    &.product-view--list{
        display: flex;
        padding:0 0 30px;
        flex-direction: column;
        align-items: center;
        @include tablet{
            align-items: flex-start;
            flex-direction: row;

        }
        .product__image{
            width: 100%;
            // @include desktops{
                //     width: 235px;
                // }
                @include mobile{
                    width: 370px;
                }
                @include tablet{
                    width: 100%;
                    margin-right: 25px;
            }
            @include desktops{
                // width: 370px;
            }
            @include large-desktops{
                width: 400px;
            }
        }
        .product-contents{
            @include tablet{
                // padding-top: 5px;
                text-align: left;
            }
        }
        .product__price{
            @include tablet{
                margin-bottom: 15px;
            }
        }
        .product-info-para{
            display: block; 
            margin-top: 15px;
            color:#b2b2b2;
        }
        .product-description{
            width: 100%;
            text-align: left;
            @include tablet{
                padding-top:0; 
                padding-left: 25px;
                width: 60%;
            }
        }
        .product-badge{
            @include tablet{
                top: 0;
                right: 10px;
            }
        }
        &:hover{
            box-shadow: none;
        }
    }
    .rating-text{
        margin-left: 12px;
        color: #b2b2b2;
        a{
            color: inherit;
            &:hover{
                color: $primary-color;
            }
        }
    }
}

.list-with-sidebar{
    .product-view--list{
        display: flex;
        padding:0 0 30px;
        flex-direction: column;
        align-items: center;
        @include tablet{
            align-items: flex-start;
            flex-direction: row;

        }
        .product__image{
            width: 100%;
                @include mobile{
                    width: 370px;
                }
                @include tablet{
                    width: 100%;
                    margin-right: 25px;
            }
            @include large-desktops{
                width: 100%;
            }
        }
}
}

/* Product Rating widgets */
.rating-widget{
    padding: 0 0 12px;
    .single-rating{
        font-size: 20px;
        color: #F5C60D;
        &+.single-rating{
            margin-left: 5px;
        }
    }
} 