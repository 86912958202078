/* Blog Details*/ 
.single-blog-item {
    .image {
        width: 100%;
        margin-bottom: 21px;
        
    }
    .video{
        margin-bottom: 21px;
        iframe{
        width: 100%;
        height: 500px;
        border:transparent;
       }
    }
   
    .content {
        .blog-title{
            font-size: 28px;
            font-weight: 400;
            color: #fff;
            font-family: 'Prata', serif;
            margin: 0 0 15px;
            text-transform: capitalize;
            white-space: nowrap;
            &:hover{
                a{
                    color: $primary-color;

                }
            }
        }
        .blog-meta{
            display: flex;
            margin-bottom: 13px;
            li{
                display: flex;
                color: $primary-color;
                // color: $text-color;
                letter-spacing: 0.5px;
                margin-bottom: 3px;
                &::after {
                    content: "|";
                    margin: 0 10px;
                }
                a{
                    color: inherit;
                    &:hover{
                        color: $primary-color;
                    }
                }
            }
        }
        .blockquote {
            margin: 30px 60px;
            position: relative;
            &:before{
                position: absolute;
                left: -30px;
                content: "\f10d";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                top: -5px;
                color: $text-color;            
            }
            p{
                font-size: 18px;
                line-height: 30px;
                font-style: italic;

            }
        }
        .desc{
            p{
                color: $text-color;
                line-height: 28px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .blog-footer{
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            margin-top: 30px;
            .tags,.share{
                display: flex;
                flex-wrap: wrap;
                span{
                    display: block;
                    color: $primary-color;
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 25px;
                    margin-right: 10px;
                }
                a{
                    font-size: 15px;
                    line-height: 25px;
                    color: $text-color;
                    &:after{
                        content: ",";
                        margin-right: 5px;
                    }
                    &:hover{
                        color: $primary-color;
                    }
                }
            }
        }
    }
}

.blog-navigation{

        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-top: 25px;
a {
    font-size: 15px;
    line-height: 20px;
    @include inline-flex;
    
    color: #acacac;
    &:hover{
        color:$primary-color;
    }
    i {
        margin-right: 10px;
        line-height: 24px;
        display: block;
    }
    &.next-blog{
        i{
            order: 2;
            margin-right: 0;
            margin-left: 10px;
        }
    }
}
} 