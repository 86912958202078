/*Mini Blog*/
.belly-mini-blog{
    text-align: center;
    position: relative;
    .blog-image{
        display: block;
        img{
            width: 100%;
        }
    }
    .blog-content{
        padding: 30px 20px;
        .blog-title{
            font-size: 18px;
            font-weight: 400;
            color: #fff;
            font-family: 'Prata', serif;
            margin: 0 0 10px;
            text-transform: capitalize;
            white-space: nowrap;
            a{
                color: inherit;
            }
            &:hover{
                color: $primary-color;
            }
        }
        .blog-subtitle{
            color: #ac8d6a;
            text-transform: capitalize;
            font-size: 12px;
            font-weight: 300;
            font-style: italic;
        }
        
        p{
            line-height: 22px;
            font-size: 14px;
            color: $text-color;
            font-weight: 300;
            margin: 10px 0 0;
        }
    }
    .blog-btn{
        a{
            margin-top: 23px;
            height: 45px;
            line-height: 42px;
            padding: 0 30px;
        }
    }
.blog-badge{
    background: #b3936d;
    padding: 8px 10px;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    line-height: 1;
    text-transform: capitalize;
    text-align: center;
    position: absolute;
    top: 10px;
    left: 10px;
        span{
            font-size: 16px;
            display: block;
            margin-bottom: 0;
        }
    }
    &.blog-style-2{
        .blog-title{
            color: $color-bunker-2;
        }
        .blog-content{
            p{
                color: #5b5b5b;
            }
        }
    }
    &.blog-style-list{
        display: flex;
        flex-direction: column;
        @include tablet{
            flex-direction: row;
        }
        .blog-image{
            @include tablet{
                width: 100%;
            }
            @include large-desktops{
                width: 500px;
            }
        }
        .blog-content{
            text-align: left;
            margin-left: 25px; 
            padding-top: 0;
            padding-left: 0;
            margin-top:10px;
            @include tablet{
                margin-top:0px;
            }
        }
    }
} 
