
/* Belley Bredcrumb Section*/
.belly-bredcrumb-section{
    padding: 60px;
    @include flex(center);
    .breadcrumb{
        padding: 0;
        margin: 0px ;
        background: none;
        justify-content: center;
        a{
            color: #adadad;
            text-transform: capitalize;
        }
        li+li:before {
            padding: 0 5px;
            color: #ccc;
            content: "/\00a0";
        }
        li:last-child {
            a {
                color: #b3936d;
            }
        }
    }
} 



