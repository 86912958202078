
.sidebar-widget{
    padding-top: 50px;
    @include desktops{
        padding-top: 0;
    }
}
.single-widget.range-slider{
    width: 100%;
    @include tablet{
        width: 90%;
    }
    @include desktops{
        width: 250px;
    }
    .belly-range-slider{
        margin-top: 10px;
        @include desktops{
            // width: 215px;
        }
    }
}

.sidebar-title{
    font-size: 20px;
    color: #ffffff;
    margin: 0;
    font-weight: 400;
    text-transform: capitalize;
    padding: 0 0 35px;
    position: relative;
    display: inline-block;
    font-family: 'Prata', serif;
}
.single-widget{
    margin-bottom: 50px;
    &:last-child{
        margin-bottom: 0;
    }
}
.widget--list{
    li{
        margin: 15px 0;
        font-weight: 300;
        a{
            color: inherit;
            &:hover{
                color: #f4a137;
            }
        }
        &:first-child{
            margin-top:0; 
        }
    }
    &.list-light{
        color: #b2b2b2;
    }
}
.widget-banner{
    img{
        transition: .4s;
        &:hover{
            opacity: .8;
        }
    }
}


/* Dynamic Rating*/ 
.d-inline-block {
    display: inline-block;
  }
  a,span{
    display: inline-block;
  }
  .rating-row p {
    margin-right: 3px;
    display: inline-block;
    font-weight: 500;
  }
  
  .rating-block {
    transform: rotateY(180deg);
  }
  .rating-block input {
    display: none;
  }
  .rating-block label {
    margin-right: 10px;
    cursor: pointer;
    position: relative;
    margin-bottom: 0;
    display: inline-block;
  }
  .rating-block label:before {
    content: "\f383";
    font-family: "Ionicons";
    position: relative;
    display: block;
    font-size: 14px;
    color: #e3e3e3;
    // color: #fec701;
    font-weight: 900;
  }
  .rating-block label:after {
    content: "\f384";
    font-family:"Ionicons";
    position: absolute;
    display: block;
    font-size: 14px;
    color: #fec701;
    font-weight: 900;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.4s;
  }
  .rating-block label:hover ~ label:after,
  .rating-block label:hover:after,
  .rating-block input:checked ~ label:after {
    opacity: 1;
  }

  .sidebar-post{
      .single-post{
        display: flex;
        margin-bottom: 10px;
        color: $text-color;
        &:last-child{
            margin-bottom: 0;
        }
        .post-image{
            width: 80px;
        }
        .post-desc{
            padding-left: 7px;
            h6{
                font-size: 18px;
                margin-bottom: 10px;
            }
        }
        a{
            color: inherit;
            &:hover{
                color: $primary-color;
            }
        }
        P{
            margin-bottom: 5px;
        }
    }
    &.tweet-posts{
        p{
            font-size: 15px;
            line-height: 22px;
            color: $text-color;
            a{
                font-weight: 500;
                color: $primary-color;
            }
        }
    }
  }
  
/* Sidebar Search*/ 
  .sidebar-search {
    display: flex;
    position: relative;
    button {
        position: absolute;
        height: 100%;
        width: 40px;
        display: flex;
        align-items: center;
        line-height: initial;
        justify-content: center;
        right: 0;
        padding: 0;
    }
        .form-control {
            background: transparent;
            border-color: #495057;
            font-size: 13px;
            color: #fff;
            padding-right: 40px;
            width: 100%;
            &:focus{
                box-shadow: none;
                outline: none;
                ~ button{
                    background: transparent;
                    color: $primary-color;
                }
            }
        }
}   