/* Belly Buttons*/
.theme-btn{
    background: $primary-color;
    color: #ffffff ;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: 'Rubik', sans-serif;
    display: inline-block;
    height: 60px;
    line-height: 60px;
    padding: 0 40px;
    border-radius: 3px;
    border: 0;
    -webkit-transition: ease .3s all;
    -moz-transition: ease .3s all;
    transition: ease .3s all;
    @include mobile-xs{
        height: 50px;
        line-height: 50px;
        padding: 0 30px;
    }
    @include mobile{
        height: 50px;
        line-height: 50px;
        padding: 0 30px;
    }
    &:hover{
        background: #fff ;
        color: #333 ;
        border-color: $primary-color;
    }

    &.btn-white{
        color: $primary-color;
        background: white;
        &:hover{
            color: $white-color;
            background: $primary-color;
        }
    }
   
}
.theme-btn-outlined{
    color: $text-color;
    border-color: $btn-color-2;
    text-transform: uppercase;
    display: inline-block;
    border-image: initial;
    text-align: center;
    background: transparent;
    font-weight: 500;
    height: 60px;
    font-size: 13px;
    border-width: 2px;
    border-style: solid;
    border-radius: 3px;
    padding: 0px 25px;
    transition: ease .3s all;
    @include inline-flex(center);
    
    &:hover{
        color: #ffffff;
        background: $primary-color;
        border-color: $primary-color;
    }
    &--primary{
        @include inline-flex;
        color: #b3936d;
        border: 2px solid #b3936d;
        background: transparent;
        transition: ease .3s all;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        font-family: 'Rubik', sans-serif;
        border-radius: 3px;
        &:hover{
            color: rgb(255, 255, 255);
            background: $primary-color;
            border-color: $primary-color;
        }
    }
    &--primary-2{
        @include inline-flex;
        color: $primary-color;
        border: 2px solid $primary-color;
        transition: ease .3s all;
        border-radius: 3px;
        &:hover{
            color: #ffffff!important;
            background: transparent;
            border-color: #fff!important;
        }
    }
    &--white-bunker{
        color: $white-color;
        border: 2px solid $white-color;
        transition: ease .3s all;
        &:hover{
            border-color: $color-bunker;
            color: #fff;
            background: $color-bunker;
        }
    }
    &--type-2{
        color: #a9a9a9;
        border-color: #565f69;
        text-transform: uppercase;
        @include inline-flex;
        border-image: initial;
        text-align: center;
        background: transparent;
        font-weight: 500;
        height: 60px;
        line-height: 58px;
        font-size: 13px;
        border-width: 2px;
        border-style: solid;
        border-radius: 3px;
        padding: 0px 25px;
        transition: ease .3s all;
        &:hover{
            color: #ffffff;
            background: $primary-color;
            border-color: $primary-color;
        }
        &--primary{
            @include inline-flex;
            color: $primary-color;
            border: 2px solid $primary-color;
            transition: ease .3s all;
            &:hover{
                color: rgb(255, 255, 255);
                background: $primary-color;
                border-color: $primary-color;
            }
        }
        &--primary-2{
            @include inline-flex;
            color: $primary-color;
            border: 2px solid $primary-color;
            transition: ease .3s all;
            &:hover{
                color: #ffffff!important;
                border-color: #565f69!important;
            }
        }
        
    }
} 

.social-btns{
    display: flex;
    li{
        margin-right: 10px;
    }
    a{
        margin-right: 10px;
        font-size: 11px;
        border-radius: 3px;
        height: 20px!important;
        padding: 0 6px; 
        color: #fff;
        transition: ease .3s all;
        i{
            margin-right:4px; 
        }
        
        &.facebook{
            background: #4267b2;
            &:hover{
                background: #365899;
            }
        }
        &.twitter{
            background: #1b95e0;
            &:hover{
                background: #0c7abf;
            }
        }
        &.google{
            background: #fe6d4c;
            &:hover{
                background: #e75a3a;
            }
        }
    }
}
